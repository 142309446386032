import "../Setup";
import "./global.css";
import { AppProps, AppType } from "next/app";
import Head from "next/head";
import { ENVIRONMENT } from "../Common/consts/ENVIRONMENT";

const APP_STORE_ID = 6475344088;

const App: AppType = (props: AppProps) => {
  const { Component, router } = props;

  const url = new URL(ENVIRONMENT.domain + router.asPath);
  const { title, description, ogImageUrl, faviconUrl } = toMetadata(url);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no"
        />
        <meta name="description" content={description} />
        <meta property="og:site_name" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="800" />
        {url.hostname.startsWith("super-real") && (
          <meta
            name="apple-itunes-app"
            content={[
              `app-id=${APP_STORE_ID}`,
              `app-argument=super-real://talents${url.pathname}`,
            ].join(", ")}
          />
        )}
        <link rel="icon" href={faviconUrl} />
        <title>{title}</title>
      </Head>
      <Component {...props.pageProps} />
    </>
  );
};

const toMetadata = (
  url: URL
): {
  title: string;
  description: string;
  ogImageUrl: string;
  faviconUrl: string;
} => {
  switch (url.hostname) {
    case "localhost":
    case "super-real.co":
    case "super-real-messenger-stg.web.app":
      return {
        title: "Super Real",
        description: "Connecting fans and their inspiration.",
        ogImageUrl: "/assets/open-graph-image.jpeg",
        faviconUrl: "/assets/favicon.png",
      };
    case "supersexy.ai":
    case "super-sexy-messenger-stg.web.app":
      return {
        title: "Super Sexy",
        description:
          "Supersexy.ai features the most beautiful AI girls on the internet. Find some eye candy or the AI girlfriend of your dreams today.",
        ogImageUrl: "/assets/supersexy-open-graph-image.jpeg",
        faviconUrl: "/assets/supersexy-favicon.png",
      };
    default:
      return {
        title: "Super Stealth",
        description: "Connecting fans and their inspiration.",
        ogImageUrl: "/assets/stealth-open-graph-image.jpeg",
        faviconUrl: "/assets/stealth-favicon.png",
      };
  }
};

export default App;
