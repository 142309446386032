import { z } from "zod";
import packageJson from "../../../package.json";

const packageJsonResult = z
  .object({ version: z.string() })
  .safeParse(packageJson);

const ProcessEnv = z.object({
  NODE_ENV: z.enum(["development", "production", "test"]),
  NEXT_PUBLIC_ENVIRONMENT: z.enum(["eu", "stg"]),
});

const result = ProcessEnv.safeParse({
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
});

export const ENVIRONMENT = {
  nodeEnv: result.success ? result.data.NODE_ENV : "development",
  name: result.success ? result.data.NEXT_PUBLIC_ENVIRONMENT : "stg",
  version: packageJsonResult.success ? packageJsonResult.data.version : "",
  domain: process.env.NEXT_PUBLIC_DOMAIN || "",
  talentDomain: process.env.NEXT_PUBLIC_TALENT_DOMAIN || "",
  stripeRedirectDomain: process.env.NEXT_PUBLIC_STRIPE_REDIRECT_DOMAIN || "",
  venusStoreUrlForIos: process.env.NEXT_PUBLIC_VENUS_STORE_URL_FOR_IOS || "",
  venusStoreUrlForAndroid:
    process.env.NEXT_PUBLIC_VENUS_STORE_URL_FOR_ANDROID || "",
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || "",
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "",
  },
  firebase: {
    region: process.env.NEXT_PUBLIC_FIREBASE_REGION || "",
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "",
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || "",
    isEmulator: process.env.NEXT_PUBLIC_FIREBASE_IS_EMULATOR === "true",
  },
};
